<template>
  <div class="order-apply">
    <div class="van-main">
      <van-cell title="项目名称" is-link :required="true" :value="form.projectName" @click="showProjectList=true" />
      <van-popup v-model="showProjectList" round position="bottom">
        <van-picker
          show-toolbar
          value-key="projectName"
          title="项目编号"
          :columns="projectList"
          @cancel="showProjectList = false"
          @confirm="confirmProject"
        />
      </van-popup>
      <van-cell title="项目编号" :value="form.projectNo" />
      <van-field v-model="form.orderName" label="订单名称" label-width="9.2em" placeholder="请输入" input-align="right" />
      <van-field v-model="form.orderNo" label="订单编号" label-width="9.2em" placeholder="请输入" input-align="right" />
      <van-cell title="合同名称" :value="form.contractName" />
      <van-cell title="合同编号" :value="form.contractNumber" />
      <van-cell title="供应商名称" is-link :value="form.usEnterpriseName" @click="showSuppliersList=true" />
      <van-popup v-model="showSuppliersList" round position="bottom">
        <van-picker
          show-toolbar
          value-key="orgName"
          title="供应商"
          :columns="suppliersList"
          @cancel="showSuppliersList = false"
          @confirm="confirmSupplier"
        />
      </van-popup>
      <van-cell title="产品类型" :value="utils.statusFormat(String(form.productType), 'ProjectProductType')" />
      <!-- <van-cell title="厂号" :value="form.factoryNumber" /> -->
      <van-cell title="一级分类" :value="form.cargoClassifyParentName" />
      <van-cell title="二级分类" is-link :value="form.cargoClassifyName" @click="showSecondLevelCategory=true" />
      <van-popup v-model="showSecondLevelCategory" round position="bottom">
        <van-picker
          show-toolbar
          value-key="classifyName"
          title="所属二级分类"
          :columns="cargoSecondClassifyList"
          @cancel="showSecondLevelCategory = false"
          @confirm="confirmSecondLevelCategory"
        />
      </van-popup>
      <van-cell title="原币币种" is-link :value="form.currencyName" @click="showCurrencyList=true" />
      <van-popup v-model="showCurrencyList" round position="bottom">
        <van-picker
          show-toolbar
          value-key="label"
          title="原币币种"
          :columns="currencyList"
          @cancel="showCurrencyList = false"
          @confirm="confirmCurrency"
        />
      </van-popup>
      <van-field v-model="form.customerTotalMoney" type="number" label="预估原币总金额" placeholder="请输入" label-width="7.2em" />
      <van-field v-model="form.customerCabinetTotalNumber" type="number" label="预估柜数" placeholder="请输入" label-width="7.2em" />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="button" @click="addProduct">
          添加采购信息
        </van-button>
      </div>
      <div class="product-list">
        <van-cell-group v-for="(item, index) in form.orderTransactionPurchaseInfoVoList" :key="item.id" class="product-item">
          <van-row class="title">
            {{ index + 1 }}
          </van-row>
          <van-field v-model="item.pi" label="合同号(PI/SC)" label-width="9.2em" :required="true" placeholder="请输入" input-align="right" />
          <van-cell v-if="$store.state.employeeInfo.userType ===4" title="合同日期" :value="utils.dateFormatUserTypeFour(item.contractDate)" @click="contractDateClick(index)" />
          <van-cell v-else title="合同日期" :value="utils.dateFormat(item.contractDate)" @click="contractDateClick(index)" />
          <van-cell title="货物名称" is-link :value="item.productName" :required="true" @click="cargoClick(index)" />
          <van-cell v-if="$store.state.employeeInfo.userType ===4" title="国家" is-link :value="utils.statusFormatUserTypeFour(item.country, 'Country')" @click="countryClick(index)" />
          <van-cell v-else title="国家" is-link :value="utils.statusFormat(item.country, 'Country')" @click="countryClick(index)" />
          <van-field v-model="item.countryOfOrigin" label="原产地" label-width="9.2em" placeholder="请输入" input-align="right" />
          <van-field v-model="item.weight" type="number" label="重量" label-width="9.2em" placeholder="请输入" input-align="right" />
          <van-field v-model="item.money" type="number" label="原币金额" label-width="9.2em" placeholder="请输入" input-align="right" />
          <van-button block type="info" color="#F2F2F2" native-type="button" @click="deleteProduct(index)">
            <span>删除</span>
          </van-button>
        </van-cell-group>
      </div>
      <div class="button-area">
        <van-button square type="info" native-type="button" @click="save">
          保存
        </van-button>
        <van-button square type="info" native-type="button" @click="save('submit')">
          提交
        </van-button>
      </div>
    </div>
    <van-popup v-model="showCargoList" round position="bottom">
      <van-picker
        show-toolbar
        value-key="cargoName"
        title="货物名称"
        :columns="cargoList"
        @cancel="showCargoList = false"
        @confirm="confirmCargo"
      />
    </van-popup>
    <van-popup v-model="showCountryList" round position="bottom">
      <van-picker
        show-toolbar
        value-key="label"
        title="国家"
        :columns="constants.Country"
        @cancel="showCountryList = false"
        @confirm="confirmCountry"
      />
    </van-popup>
    <van-calendar v-model="showContractDate" @confirm="onContractDateConfirm" />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Form, Field, Toast, Popup, Picker, SwipeCell, Calendar, NavBar, Col, Row } from 'vant'
class BaseGoodsProp {
  // 订单业务ID
  orderId = ''
  // 合同号（PI/SC）
  pi = ''
  // 合同日期
  contractDate = undefined
  // 货物id
  cargoId = ''
  // 货物名称
  productName = ''
  // 国家
  country = ''
  // 原产地
  countryOfOrigin = ''
  // HS编码
  hsCode = ''
  // 原币单价
  unitPrice = undefined
  // 币种
  currency = ''
  // 重量
  weight = undefined
  // 原币金额
  money = ''
  // 采购合同
  fileDetailInfoVoList = []
  // 期货单价USD/KG
  futuresGoodsPriceUsdKg = ''
  // 期货单价RMB/KG
  futuresGoodsPriceRmbKg = ''
  // 现货单价
  spotGoodsPriceRmbKg = ''

  constructor (data) {
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
  }
}
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    [SwipeCell.name]: SwipeCell,
    [Calendar.name]: Calendar,
    [NavBar.name]: NavBar,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data () {
    return {
      id: this.$route.query.id,
      showCountryList: false,
      showEditPopup: false,
      editForm: {},
      showContractDate: false,
      projectList: [],
      showProjectList: false,
      suppliersList: [],
      showSuppliersList: false,
      firstLevelCategory: [],
      secondLevelCategory: [],
      showCargoList: false,
      cargoSecondClassifyList: [],
      cargoClassifyList: [],
      cargoList: [],
      form: {
        projectNo: '',
        projectName: '请选择项目',
        orderName: '',
        orderNo: '',
        contractName: '',
        contractNumber: '',
        usEnterpriseId: '',
        usEnterpriseName: '请选择供应商',
        usEnterpriseOrgNo: '',
        factoryNumber: '',
        cargoClassifyParentName: '',
        cargoClassifyName: '请选择二级分类',
        currency: '',
        currencyName: '',
        projectId: '',
        productType: '',
        dsEnterpriseId: this.$store.state.employeeInfo.enterpriseId,
        firstCategoryId: '',
        secondCategoryId: '',
        cargoClassifyParentId: '',
        cargoClassifyId: '',
        orderTransactionPurchaseInfoVoList: [],
        totalWeight: 0,
        totalMoney: 0,
        // 客户预估总柜数
        customerCabinetTotalNumber: '',
        // 客户预估原币总金额
        customerTotalMoney: '',
        // 预估汇率
        agreedExchangeRate: undefined,
        // 本币估值
        valuation: undefined,
        // 预付款比例
        advanceChargeProportion: undefined,
        // 原币预付款金额
        advancePaymentMoney: undefined,
        // 原币尾款金额
        balanceMoney: undefined,
        // 尾款预计支付时间
        balanceEstimatePayTime: ''
      },
      showSecondLevelCategory: false,
      currencyList: this.constants.OriginalCurrency,
      showCurrencyList: false,
      orderTradingSchemeForm: {
        id: '',
        orderId: '',
        cargoClassifyId: '',
        cargoClassifyParentId: '',
        orderTransactionPurchaseInfoVoList: [],
        projectId: '',
        currency: '',
        totalWeight: 0,
        // 客户预估总柜数
        customerCabinetTotalNumber: '',
        // 客户预估原币总金额
        customerTotalMoney: ''
      },
      editIndex: ''
    }
  },
  computed: {
  },
  created () {
    this.getProjectList()
    this.getSuppliersList()
    this.getCategoryList()
  },
  methods: {
    getDetail () {
      Toast.loading({ duration: 0, forbidClick: true })
      this.api.order.detailNongtou({ orderId: this.id }).then(result => {
        this.form = this._.assign(this.form, result.data.value)
        const project = this._.find(this.projectList, { projectId: this.form.projectId })
        if (project) {
          this.form.contractName = project.contractName
          this.form.contractNumber = project.contractNumber
        }
        if (!this._.isEmpty(this.form.fileDetailInfoVoList)) {
          this.fileList = []
          this.form.fileDetailInfoVoList.forEach(item => {
            item.name = item.fileName
            this.fileList.push(item)
          })
        }
        this.getDealGoodsList()
      }).finally(() => {
        Toast.clear()
      })
    },
    // 获取货物列表
    getDealGoodsList () {
      this.api.order.dealPlanNongtou({ orderId: this.form.orderId }).then(result => {
        const data = result.data.value
        // this.form = this._.assign(this.form, data)
        this.form.currency = data.currency
        this.form.customerCabinetTotalNumber = data.customerCabinetTotalNumber
        this.form.customerTotalMoney = data.customerTotalMoney
        const orderTransactionPurchaseInfoVoList = []
        if (!this._.isEmpty(data.orderTransactionPurchaseInfoVoList)) {
          data.orderTransactionPurchaseInfoVoList.forEach(item => {
            orderTransactionPurchaseInfoVoList.push(new BaseGoodsProp(item))
          })
        }
        this.form.orderTransactionPurchaseInfoVoList = orderTransactionPurchaseInfoVoList
        this.changeCurrency(this.form.currency)
      }).catch(e => {
      })
    },
    // 项目
    getProjectList () {
      this.api.project.list({ projectStatus: 3, dsEnterpriseId: this.$store.state.employeeInfo.enterpriseId }).then(result => {
        this.projectList = result.data.value || []
        if (this.id) {
          this.getDetail()
        }
      })
    },
    // 供应商
    getSuppliersList () {
      this.api.partnerManagement.overseasSuppliersList({ pageSize: 100, orgType: 5 }).then(result => {
        this.suppliersList = result.data.value.list || []
      })
    },
    // changeSupplier (enterpriseId) {
    //   const row = this._.find(this.suppliersList, { enterpriseId: enterpriseId }) || {}
    //   this.form.usEnterpriseName = row.orgName
    //   this.form.usEnterpriseOrgNo = row.orgNo
    //   this.form.usAdmitId = row.admitId
    //   this.form.factoryNumber = row.factoryNumber
    // },
    changeCurrency (val) {
      const row = this._.find(this.currencyList, { value: String(val) }) || {}
      this.form.currencyName = row.label
    },
    confirmProject (row) {
      // console.log(row)
      this.showProjectList = false
      delete row.processId
      const projectData = row
      const saveProjectData = this._.cloneDeep(projectData)
      delete saveProjectData.id
      delete saveProjectData.usEnterpriseId
      this.form = Object.assign(this.form, saveProjectData)
      const firstCategory = this._.find(this.cargoClassifyList, { cargoClassifyId: this.form.cargoClassifyId }) || {}
      this.form.cargoClassifyParentId = firstCategory.cargoClassifyId
      this.form.cargoClassifyParentName = firstCategory.classifyName
      this.changeFirstCargoType(this.form.cargoClassifyId)
    },
    confirmSupplier (row) {
      this.form.usEnterpriseId = row.enterpriseId
      this.form.usEnterpriseName = row.orgName
      this.form.usEnterpriseOrgNo = row.orgNo
      this.form.usAdmitId = row.admitId
      this.form.factoryNumber = row.factoryNumber
      this.showSuppliersList = false
    },
    confirmCurrency (row) {
      this.form.currency = row.value
      this.form.currencyName = row.label
      this.showCurrencyList = false
    },
    // 获取分类列表
    getCategoryList () {
      return new Promise((resolve, reject) => {
        this.api.cargoManagement.categoryList({ cargoParentId: 0 }).then(result => {
          this.cargoClassifyList = result.data.value || []
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        })
      })
    },
    // 改变一级分类
    changeFirstCargoType (value, jumpChangeType = false) {
      this.form.secondCategoryId = ''
      const data = this._.find(this.cargoClassifyList, { cargoClassifyId: value }) || {}
      this.cargoSecondClassifyList = data.children || []
      if (!jumpChangeType) {
        this.changeCargoType(value)
      }
    },
    // 改变二级分类
    changeSecondCargoType (value) {
      this.form.cargoClassifyId = value
      this.changeCargoType(value)
    },
    // 改变分类
    changeCargoType (val) {
      this.form.orderTransactionPurchaseInfoVoList = []
      this.getGoodsList(val)
    },
    getGoodsList (val) {
      this.cargoList = []
      this.api.cargoManagement.goodsList({ cargoClassifyId: val }).then(result => {
        this.cargoList = result.data.value
      })
    },
    confirmSecondLevelCategory (row) {
      this.form.cargoClassifyId = row.cargoClassifyId
      this.form.cargoClassifyName = row.classifyName
      this.getGoodsList(row.cargoClassifyId)
      this.showSecondLevelCategory = false
    },
    contractDateClick (index) {
      this.showContractDate = true
      this.editIndex = index
    },
    onContractDateConfirm (value) {
      this.form.orderTransactionPurchaseInfoVoList[this.editIndex].contractDate = this.utils.dateFormat(value)
      this.showContractDate = false
    },
    cargoClick (index) {
      this.showCargoList = true
      this.editIndex = index
    },
    confirmCargo (row) {
      this.form.orderTransactionPurchaseInfoVoList[this.editIndex].productName = row.cargoName
      this.form.orderTransactionPurchaseInfoVoList[this.editIndex].cargoId = row.cargoId
      this.showCargoList = false
    },
    countryClick (index) {
      this.showCountryList = true
      this.editIndex = index
    },
    confirmCountry (row) {
      this.form.orderTransactionPurchaseInfoVoList[this.editIndex].country = row.value
      this.showCountryList = false
    },
    addProduct () {
      this.form.orderTransactionPurchaseInfoVoList.push(new BaseGoodsProp({}))
    },
    deleteProduct (index) {
      this.form.orderTransactionPurchaseInfoVoList.splice(index, 1)
    },
    submitProduct () {
      if (this.editForm.pi === '') {
        Toast.fail('请输入合同编号')
        return false
      }
      if (this.editForm.productName === '') {
        Toast.fail('请选择货物名称')
        return false
      }
      this.form.orderTransactionPurchaseInfoVoList.push(this.editForm)
      this.showEditPopup = false
    },
    // save () {
    //   if (!this.form.projectId) {
    //     Toast.fail('请选择项目')
    //     return false
    //   }
    //   if (!this.id) {
    //     this.api.order.addOrder(this.form).then(result => {
    //       this.orderTradingSchemeForm.orderId = result.data.value.orderId
    //       this.api.order.AddDealPlanNongTou(this.orderTradingSchemeForm).then(result => {
    //         Toast.success(result.data.message || '保存成功')
    //         this.$router.back()
    //       }).finally(() => {
    //       })
    //     }).finally(() => {
    //     })
    //   } else {
    //     this.api.order.editOrderNt(this.form).then(result => {
    //       this.api.order.updateDealPlanNongTou(this.orderTradingSchemeForm).then(result => {
    //         Toast.success(result.data.message || '保存成功')
    //         this.$router.back()
    //       }).finally(() => {
    //       })
    //     }).finally(() => {
    //     })
    //   }
    //   return false
    // },
    // submit () {
    //   if (!this.form.projectId) {
    //     Toast.fail('请选择项目')
    //     return false
    //   }
    //   this.api.order.submitOrder(this.form).then(result => {
    //     Toast.success(result.data.message || '提交成功')
    //     this.$router.back()
    //   }).finally(() => {
    //   })
    // },
    editFormData (formData) {
      return new Promise((resolve, reject) => {
        // 处理货物信息数据
        const goodsFormData = this._.cloneDeep(this.form)
        goodsFormData.orderTransactionPurchaseInfoVoList.forEach(item => {
          item.currency = goodsFormData.currency
          item.orderId = goodsFormData.orderId
        })
        this.api.order.updateDealPlanNongTou(goodsFormData).then(result => {
          Toast.success(result.data.message || '保存成功')
          resolve(result)
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    saveOrder (type, formData) {
      return new Promise((resolve, reject) => {
        let api = this.api.order.addOrderNt
        if (formData.id) {
          api = this.api.order.editOrderNt
          if (type === 'submit') {
            formData.orderStatus = 1
          }
        } else {
          if (type === 'submit') {
            formData.submitFlag = 1
          }
        }
        api(formData).then(result => {
          if (result.data.value) {
            this.form = this._.assign(this.form, result.data.value)
          }
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    save (type) {
      if (!this.form.projectId) {
        Toast.fail('请选择项目')
        return false
      }
      if (this._.isEmpty(this.form.orderTransactionPurchaseInfoVoList)) {
        Toast.fail('添加采购信息')
        return false
      }
      this.saveOrder(type, this.form).then(result => {
        this.editFormData(result).then(result => {
          Toast.success(result.data.message || '保存成功')
          if (type === 'submit') {
            this.$router.back()
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.button-area {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 20px);
  left: 50%;
  margin-left: calc(-50% + 10px);
  padding-bottom: 15px;
  background-color: #FFF;
  z-index: 99;
  >button {
    width: calc(50% - 5px);
  }
  >button:last-child {
    margin-left: 10px;
  }
}
.product-list {
  padding: 0px 20px 80px 20px;
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    background-color: #F2F2F2;
  }
  .product-item {
    border:1px solid #F2F2F2;
    border-radius: 5px;
  }
  button {
    span {
      color:red;
    }
  }
}

</style>
