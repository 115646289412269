var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-apply" },
    [
      _c(
        "div",
        { staticClass: "van-main" },
        [
          _c("van-cell", {
            attrs: {
              title: "项目名称",
              "is-link": "",
              required: true,
              value: _vm.form.projectName
            },
            on: {
              click: function($event) {
                _vm.showProjectList = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showProjectList,
                callback: function($$v) {
                  _vm.showProjectList = $$v
                },
                expression: "showProjectList"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "projectName",
                  title: "项目编号",
                  columns: _vm.projectList
                },
                on: {
                  cancel: function($event) {
                    _vm.showProjectList = false
                  },
                  confirm: _vm.confirmProject
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: { title: "项目编号", value: _vm.form.projectNo }
          }),
          _c("van-field", {
            attrs: {
              label: "订单名称",
              "label-width": "9.2em",
              placeholder: "请输入",
              "input-align": "right"
            },
            model: {
              value: _vm.form.orderName,
              callback: function($$v) {
                _vm.$set(_vm.form, "orderName", $$v)
              },
              expression: "form.orderName"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "订单编号",
              "label-width": "9.2em",
              placeholder: "请输入",
              "input-align": "right"
            },
            model: {
              value: _vm.form.orderNo,
              callback: function($$v) {
                _vm.$set(_vm.form, "orderNo", $$v)
              },
              expression: "form.orderNo"
            }
          }),
          _c("van-cell", {
            attrs: { title: "合同名称", value: _vm.form.contractName }
          }),
          _c("van-cell", {
            attrs: { title: "合同编号", value: _vm.form.contractNumber }
          }),
          _c("van-cell", {
            attrs: {
              title: "供应商名称",
              "is-link": "",
              value: _vm.form.usEnterpriseName
            },
            on: {
              click: function($event) {
                _vm.showSuppliersList = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showSuppliersList,
                callback: function($$v) {
                  _vm.showSuppliersList = $$v
                },
                expression: "showSuppliersList"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "orgName",
                  title: "供应商",
                  columns: _vm.suppliersList
                },
                on: {
                  cancel: function($event) {
                    _vm.showSuppliersList = false
                  },
                  confirm: _vm.confirmSupplier
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "产品类型",
              value: _vm.utils.statusFormat(
                String(_vm.form.productType),
                "ProjectProductType"
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "一级分类",
              value: _vm.form.cargoClassifyParentName
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "二级分类",
              "is-link": "",
              value: _vm.form.cargoClassifyName
            },
            on: {
              click: function($event) {
                _vm.showSecondLevelCategory = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showSecondLevelCategory,
                callback: function($$v) {
                  _vm.showSecondLevelCategory = $$v
                },
                expression: "showSecondLevelCategory"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "classifyName",
                  title: "所属二级分类",
                  columns: _vm.cargoSecondClassifyList
                },
                on: {
                  cancel: function($event) {
                    _vm.showSecondLevelCategory = false
                  },
                  confirm: _vm.confirmSecondLevelCategory
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "原币币种",
              "is-link": "",
              value: _vm.form.currencyName
            },
            on: {
              click: function($event) {
                _vm.showCurrencyList = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showCurrencyList,
                callback: function($$v) {
                  _vm.showCurrencyList = $$v
                },
                expression: "showCurrencyList"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "label",
                  title: "原币币种",
                  columns: _vm.currencyList
                },
                on: {
                  cancel: function($event) {
                    _vm.showCurrencyList = false
                  },
                  confirm: _vm.confirmCurrency
                }
              })
            ],
            1
          ),
          _c("van-field", {
            attrs: {
              type: "number",
              label: "预估原币总金额",
              placeholder: "请输入",
              "label-width": "7.2em"
            },
            model: {
              value: _vm.form.customerTotalMoney,
              callback: function($$v) {
                _vm.$set(_vm.form, "customerTotalMoney", $$v)
              },
              expression: "form.customerTotalMoney"
            }
          }),
          _c("van-field", {
            attrs: {
              type: "number",
              label: "预估柜数",
              placeholder: "请输入",
              "label-width": "7.2em"
            },
            model: {
              value: _vm.form.customerCabinetTotalNumber,
              callback: function($$v) {
                _vm.$set(_vm.form, "customerCabinetTotalNumber", $$v)
              },
              expression: "form.customerCabinetTotalNumber"
            }
          }),
          _c(
            "div",
            { staticStyle: { margin: "16px" } },
            [
              _c(
                "van-button",
                {
                  attrs: { block: "", type: "info", "native-type": "button" },
                  on: { click: _vm.addProduct }
                },
                [_vm._v(" 添加采购信息 ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "product-list" },
            _vm._l(_vm.form.orderTransactionPurchaseInfoVoList, function(
              item,
              index
            ) {
              return _c(
                "van-cell-group",
                { key: item.id, staticClass: "product-item" },
                [
                  _c("van-row", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(index + 1) + " ")
                  ]),
                  _c("van-field", {
                    attrs: {
                      label: "合同号(PI/SC)",
                      "label-width": "9.2em",
                      required: true,
                      placeholder: "请输入",
                      "input-align": "right"
                    },
                    model: {
                      value: item.pi,
                      callback: function($$v) {
                        _vm.$set(item, "pi", $$v)
                      },
                      expression: "item.pi"
                    }
                  }),
                  _vm.$store.state.employeeInfo.userType === 4
                    ? _c("van-cell", {
                        attrs: {
                          title: "合同日期",
                          value: _vm.utils.dateFormatUserTypeFour(
                            item.contractDate
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.contractDateClick(index)
                          }
                        }
                      })
                    : _c("van-cell", {
                        attrs: {
                          title: "合同日期",
                          value: _vm.utils.dateFormat(item.contractDate)
                        },
                        on: {
                          click: function($event) {
                            return _vm.contractDateClick(index)
                          }
                        }
                      }),
                  _c("van-cell", {
                    attrs: {
                      title: "货物名称",
                      "is-link": "",
                      value: item.productName,
                      required: true
                    },
                    on: {
                      click: function($event) {
                        return _vm.cargoClick(index)
                      }
                    }
                  }),
                  _vm.$store.state.employeeInfo.userType === 4
                    ? _c("van-cell", {
                        attrs: {
                          title: "国家",
                          "is-link": "",
                          value: _vm.utils.statusFormatUserTypeFour(
                            item.country,
                            "Country"
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.countryClick(index)
                          }
                        }
                      })
                    : _c("van-cell", {
                        attrs: {
                          title: "国家",
                          "is-link": "",
                          value: _vm.utils.statusFormat(item.country, "Country")
                        },
                        on: {
                          click: function($event) {
                            return _vm.countryClick(index)
                          }
                        }
                      }),
                  _c("van-field", {
                    attrs: {
                      label: "原产地",
                      "label-width": "9.2em",
                      placeholder: "请输入",
                      "input-align": "right"
                    },
                    model: {
                      value: item.countryOfOrigin,
                      callback: function($$v) {
                        _vm.$set(item, "countryOfOrigin", $$v)
                      },
                      expression: "item.countryOfOrigin"
                    }
                  }),
                  _c("van-field", {
                    attrs: {
                      type: "number",
                      label: "重量",
                      "label-width": "9.2em",
                      placeholder: "请输入",
                      "input-align": "right"
                    },
                    model: {
                      value: item.weight,
                      callback: function($$v) {
                        _vm.$set(item, "weight", $$v)
                      },
                      expression: "item.weight"
                    }
                  }),
                  _c("van-field", {
                    attrs: {
                      type: "number",
                      label: "原币金额",
                      "label-width": "9.2em",
                      placeholder: "请输入",
                      "input-align": "right"
                    },
                    model: {
                      value: item.money,
                      callback: function($$v) {
                        _vm.$set(item, "money", $$v)
                      },
                      expression: "item.money"
                    }
                  }),
                  _c(
                    "van-button",
                    {
                      attrs: {
                        block: "",
                        type: "info",
                        color: "#F2F2F2",
                        "native-type": "button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteProduct(index)
                        }
                      }
                    },
                    [_c("span", [_vm._v("删除")])]
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "button-area" },
            [
              _c(
                "van-button",
                {
                  attrs: { square: "", type: "info", "native-type": "button" },
                  on: { click: _vm.save }
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { square: "", type: "info", "native-type": "button" },
                  on: {
                    click: function($event) {
                      return _vm.save("submit")
                    }
                  }
                },
                [_vm._v(" 提交 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showCargoList,
            callback: function($$v) {
              _vm.showCargoList = $$v
            },
            expression: "showCargoList"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "value-key": "cargoName",
              title: "货物名称",
              columns: _vm.cargoList
            },
            on: {
              cancel: function($event) {
                _vm.showCargoList = false
              },
              confirm: _vm.confirmCargo
            }
          })
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showCountryList,
            callback: function($$v) {
              _vm.showCountryList = $$v
            },
            expression: "showCountryList"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "value-key": "label",
              title: "国家",
              columns: _vm.constants.Country
            },
            on: {
              cancel: function($event) {
                _vm.showCountryList = false
              },
              confirm: _vm.confirmCountry
            }
          })
        ],
        1
      ),
      _c("van-calendar", {
        on: { confirm: _vm.onContractDateConfirm },
        model: {
          value: _vm.showContractDate,
          callback: function($$v) {
            _vm.showContractDate = $$v
          },
          expression: "showContractDate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }